@import url("https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* ---------------------- VARIABLES ---------------------- */

:root {
  --background: linear-gradient(45deg, #1764d8, #12ab8c, #97ca4a);
  --dashboardBackgroundColor: whitesmoke;
  --gradient: linear-gradient(45deg, #12ab8c, #97ca4a);
  --textColor1: #fff;
  --textColor2: #333;
  --placeholderText: #ffffffa4;
  --buttonColor: #12ab8c;
  --boxShadowColor: #00000014;
  --mainPageLogoColor: #ffffffa3;
  --sidebarGreyedOutColor: #ffffff61;
  --sidebarHoverColor: #0000000f;
  --profileDropdownHoverColor: #e1e1e1;
  --watchlistHoverBorderColor: #d1d1d1;
  --navBarInputColor: #0000003d;
  --loginBackgroundColor: #ffffff1a;
  --popupBackgroundColor: #000000a3;
  --contentAlternationColor: #00000014;
  --announcementsIconHoverColor: #0000001c;
}

/* ---------------------- GLOBALS ---------------------- */

* {
  margin: 0;
  padding: 0;
  color: var(--textColor1);
  font-family: Chivo;
}
.App {
  font-family: sans-serif;
  text-align: center;
}
body {
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #e7e7e7;
}
::-webkit-scrollbar-thumb {
  background: #d1d1d1;
}
::-webkit-scrollbar-thumb:hover {
  background: #c0c0c0;
}
input:focus-visible {
  outline: 0;
}

/* ---------------------- HOME ---------------------- */

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 50px;
  min-height: 100vh;
  min-width: 100vw;
  background: var(--background);
}
.home h1 {
  font-weight: 600;
  font-size: 300px;
  line-height: 220px;
}
.home h2 {
  font-weight: 400;
}
.buttonsWrapper {
  display: flex;
  column-gap: 30px;
}
.invite,
.enter {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
  background: var(--textColor1);
  border-radius: 50px;
  border: none;
  padding: 16px 60px;
  cursor: pointer;
}
.invite:hover,
.enter:hover {
  opacity: 0.7 !important;
}
.home button h3 {
  font-size: 16px;
  font-weight: 600;
  color: var(--textColor2);
  line-height: 30px;
}
.home button img {
  width: 30px;
  height: 30px;
}
.mask1 {
  background-image: url('./images/bytes.png');
  cursor: default;
  font-size: 250px;
  font-weight: 700;
  -webkit-text-fill-color: var(--mainPageLogoColor);
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
  line-height: 182px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

/* ---------------------- DASHBOARD ---------------------- */

.dashboard {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  background: var(--dashboardBackgroundColor);
  display: flex;
}
.dashboardLeft {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  background: var(--dashboardBackgroundColor);
  display: flex;
  animation: slideLeft 0.5s ease-in-out;
}
.dashboardRight {
  position: absolute;
  top: 0;
  left: 100vw;
  min-height: 100vh;
  min-width: 100vw;
  background: var(--dashboardBackgroundColor);
  display: flex;
  animation: slideRight 0.5s ease-in-out;
}
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 230px;
  position: sticky;
  top: 0;
  left: 0;
  min-height: 100vh;
  max-height: 100vh;
  background: var(--gradient);
  padding: 0 15px;
}
.innerSidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.sidebar .logo {
  text-align: center;
  cursor: pointer;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}
.logo {
  transition: .2s ease-in-out;
}
.logo:hover {
  transform: rotate(-3deg);
}
.logo img {
  height: 100px;
}
.logo img:hover {
  filter: none !important;
}
.sidebar button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 10px 20px;
  margin: 3px 0;
  text-align: left;
  font-weight: 600;
  border-radius: 10px;
}
.greyedOut {
  cursor: default !important;
  color: var(--sidebarGreyedOutColor);
  font-weight: 400;
}
.sidebar button:hover {
  background: var(--sidebarHoverColor);
}
.mainContent {
  padding: 0 10px;
  width: 100%;
}
.mainContent .navbar {
  padding: 0 20px;
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mainContent .navbar input {
  padding: 15px 20px;
  width: 300px;
  background: var(--navBarInputColor);
  border: none;
  border-radius: 15px;
  opacity: 0.5;
  color: #000;
}
.mainContent .navbar input::placeholder {
  color: #000 !important;
}
.mainContent .navbar .logo {
  display: none;
}
.mainContent .navbar h1 {
  font-weight: 400;
  color: var(--textColor2);
}
.mainContent .navbar div {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}
.mainContent .navbar input::placeholder {
  color: var(--placeholderText);
}
.mainContent .navbar input:hover {
  opacity: 1;
}
.mainContent .navbar img {
  cursor: pointer;
  filter: brightness(0.2);
  height: 45px;
}
.mainContent .navbar img:hover {
  filter: brightness(0.5);
}

/* ---------------------- WATCHLISTS ---------------------- */

.discord,
.watchlistScreen {
  display: flex;
  flex-wrap: wrap;
}
.watchlist {
  width: 400px;
  background: var(--textColor1);
  border-radius: 15px;
  width: 250px;
  height: 250px;
  margin: 20px 20px;
  cursor: pointer;
  box-shadow: 0 0 5px var(--boxShadowColor);
  position: relative;
}
.watchlist:hover {
  filter: brightness(1.1);
  border: 1px solid var(--watchlistHoverBorderColor);
  margin: 19px 19px;
}
.watchlistServerListOuter {
  height: calc(100% - 42px);
  overflow: hidden;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.watchlistServerList {
  overflow: auto;
  height: 100%;
}
.watchlist h1 {
  font-size: 18px;
  padding: 10px;
  font-weight: 400;
  background: var(--gradient);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  justify-content: center;
}
.watchlist h2 {
  font-size: 12px;
  font-weight: 400;
  padding: 6px 10px;
  color: var(--textColor2);
}
.watchlist.new {
  position: relative;
}
.watchlist.new div::before,
.watchlist.new div::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 4px;
  height: 60px;
  background: var(--gradient);
}
.watchlist.new div::after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.edit-delete {
  display: flex;
  justify-content: center;
  width: 80%;
  align-items: flex-end;
  flex-direction: column;
  row-gap: 10px
}
.edit-delete button {
  background: none;
  border: solid var(--buttonColor) 1px;
  border-radius: 20px;
  padding: 3px 10px;
  margin: 0 5px;
  cursor: pointer;
  color: var(--buttonColor);
}
.edit-delete svg {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  cursor: pointer;
}
.edit-delete svg path {
  fill: var(--buttonColor);
}
.edit-delete svg:hover {
  opacity: 0.7;
}
.edit-delete button:hover {
  filter: brightness(0.7);
}
.edit-delete h4 {
  color: #12ab8c;
  font-weight: 200;
  margin: 0 5px;
  font-size: 12px;
}
.edit-deleteInner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pulsating {
  width: 10px;
  height: 10px;
  background: rgb(38, 255, 0);
  border-radius: 50%;
  animation: pulse 2s linear infinite;
}

/* ---------------------- INDIVIDUAL WATCHLISTS SCREEN   ---------------------- */

.watchlistScreen {
  display: flex;
  justify-content: center;
  align-items: center;
}
.individualWatchlist {
  width: 40%;
  background: var(--textColor1);
  border-radius: 15px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  position: relative;
  box-shadow: 0 0 5px var(--boxShadowColor);
}
.announcements {
  display: flex;
  height: calc(100% - 70px);
  width: 100%;
  justify-content: flex-end;
}
.leftColumn {
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: var(--gradient);
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 70px);
  padding-top: 70px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.announcementIcon {
  position: relative;
}
.unread {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  background: #ff2000 !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.leftColumn div {
  width: 100%;
  display: flex;
  justify-content: center;
  background: none;
  padding: 5px 0;
  cursor: pointer;
}
.leftColumn div:hover {
  background: var(--announcementsIconHoverColor);
}
.leftColumn div img {
  width: 45px;
}
.enlarge {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: var(--popupBackgroundColor);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}
.enlarge img, .enlarge video {
  max-width: 70vw;
  max-height: 90vh;
}
.discordLogo {
  padding: 0 !important;
  position: absolute;
  top: 0;
  padding: 0;
}
.discordLogo img {
  width: 70px !important;
  height: 70px;
  border-top-left-radius: 15px;
}
.discordLogo:hover {
  background: none !important;
}
.announcementsTitle {
  display: flex;
  width: 100%;
}
.announcementsTitle h2 {
  font-size: 16px;
  font-weight: 400;
}
.subAnnouncementsOuter {
  width: calc(100% - 70px);
  overflow: hidden;
  border-bottom-right-radius: 15px;
}
.subAnnouncements {
  width: 100%;
  overflow: auto;
  height: 100%;
  overflow-x: hidden;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.loading img {
  width: 50px;
  height: 50px;
}
.subAnnouncements h1,
.subAnnouncements h2,
.subAnnouncements h3 {
  padding: 10px 20px;
}
.announcementText, .subAnnouncements h3 {
  font-size: 14px;
  color: var(--textColor2);
  padding: 10px 20px;
}
.announcement img, .announcement video {
  max-width: 30%;
  max-height: 200px;
  margin: 5px 20px;
  cursor: pointer;
}
.announcementText b, .announcementText i, .announcementText s {
  color: #000;
}
.announcement a {
  color: #000;
}
.announcement b {
  color: #000;
}
.announcement:nth-child(odd) {
  background: var(--contentAlternationColor);
}
.announcement h4 {
  color: #000;
  font-weight: 100;
  font-size: 12px;
  text-align: right;
  padding: 0 5px 5px 0;
}
.individualWatchlist .header {
  background: var(--gradient);
  width: calc(100% - 70px);
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto;
  border-top-right-radius: 15px;
}

/* ---------------------- NEW WATCHLISTS POPUP ---------------------- */

.hamburgerWrapper {
  position: fixed;
  z-index: 2;
  right: 0;
  top: 0;
  background: var(--textColor1);
  width: 30px;
  height: 30px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  border: 1px solid var(--textColor1);
}
.hamburger1,
.hamburger2 {
  width: 23px;
  height: 2px;
  background: var(--textColor2);
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
  content: "";
  position: absolute;
}
.hamburger1 {
  transform: rotate(45deg) translate(12px, 7px);
}
.hamburger2 {
  transform: rotate(-45deg) translate(-7px, 12px);
}
.newWatchlist {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--textColor1);
  width: 50vw;
  height: 60vh;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  overflow: hidden;
  z-index: 100;
}
.newWatchlist .header {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  background: var(--gradient);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.newWatchlistInner {
  height: 100%;
  width: 100%;
  overflow: auto;
}
.newWatchlistInner input {
  max-height: 20px;
  padding: 15px 20px;
  width: 49%;
  border: 0;
  background: none;
  border-bottom: 1px solid var(--textColor1);
}
.watchlistInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newWatchlistInner h2 {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 100;
  text-align: center;
}
.selectDiscords {
  position: relative;
  height: auto;
  overflow: auto;
}
.selectDiscords div {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.didntsee {
  font-style: italic;
  font-weight: 400;
  text-align: center;
  padding: 10px !important;
  opacity: 0.7;
}
.plusorminus {
  height: 30px;
  width: 30px;
  padding: 0 20px;
  filter: brightness(0);
}
.red {
  filter: brightness(1) !important;
}
.selectDiscords div:hover {
  opacity: 0.6;
}
.selectDiscords div:nth-child(odd) {
  background: var(--contentAlternationColor);
}
.selectDiscords div:nth-child(even) {
  background: var(--textColor1);
}
.selectDiscords h1 {
  font-size: 16px;
  padding: 0 20px;
  color: var(--textColor2);
}
.watchlistInputs button:hover {
  filter: brightness(1.2);
}
.newWatchlistInner input::placeholder {
  color: var(--placeholderText);
}
.outerWatchlist {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: var(--popupBackgroundColor);
  z-index: 100;
}
.confirmChange {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.confirmChange button {
  margin: 8px;
  border: 1px solid var(--textColor1);
  color: var(--textColor1);
  background: none;
  border-radius: 20px;
  padding: 3px 10px;
  cursor: pointer;
}
.confirmChange button:hover {
  filter: brightness(0.8);
}

/* ---------------------- PROFILE DROPDOWN ---------------------- */

.profileDropdown {
  position: relative;
  z-index: 10001;
}
.profileDropdown img {
  height: 50px;
}
.profileDropdown div {
  position: absolute;
  flex-direction: column;
  top: 100%;
  right: 0;
  width: 200px;
  margin: 10px 0;
  background: var(--textColor1);
  padding: 10px 0;
  border-radius: 15px;
  box-shadow: 0 0 5px var(--boxShadowColor);
}
.profileDropdown button {
  border: none;
  color: var(--textColor2);
  padding: 15px 0;
  margin: 5px 0;
  background: none;
  width: 100%;
  cursor: pointer;
}
.profileDropdown button:hover {
  background: var(--profileDropdownHoverColor);
}

/* ---------------------- AUTH----------------------- */

.formContainerOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}
.formContainerInner {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 40%;
  height: 500px;
  justify-content: center;
  align-items: center;
}
.formContainerFields {
  display: flex;
  flex-direction: column;
  width: 80%;
  row-gap: 30px;
}
.formInput {
  background-color: var(--loginBackgroundColor);
  color: var(--textColor2);
  height: 30px;
  font-size: 15px;
  border: solid grey 1px;
  border-radius: 5px;
  padding: 10px 20px;
}
.buttonContainer {
  display: flex;
  height: 50px;
}
.buttonContainer button:nth-child(1) {
  margin-right: -15px;
}
.toggledButton {
  flex: 1;
  border: solid var(--buttonColor) 1px;
  background: var(--gradient);
  border-radius: 10px;
  cursor: pointer;
  color: var(--textColor1);
  font-size: 20px;
  border: none;
  z-index: 1;
}
.untoggledButton {
  flex: 1;
  border: solid var(--buttonColor) 1px;
  background: var(--loginBackgroundColor);
  border-radius: 10px;
  cursor: pointer;
  color: var(--buttonColor);
  font-size: 20px;
}
.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  background: var(--gradient);
}
.or {
  color: var(--buttonColor);
  font-size: 20px;
  text-align: center;
  justify-content: center;
}
.forgotPassword {
  font-size: 15px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: var(--buttonColor);
  margin: 0 auto 0 0;
}
.forgotPassword:hover {
  color: grey;
}

/* ---------------------- ALERT POPUP----------------------- */

.react-confirm-alert-overlay {
  background: var(--popupBackgroundColor);
}
.react-confirm-alert-body {
  font-family: Chivo;
}
.react-confirm-alert-button-group > button {
  background: var(--gradient) !important;
}
.react-confirm-alert-body > h1 {
  margin: 0 0 5px;
  color: var(--textColor2);
  font-size: 20px;
}

/* ---------------------- MAIN DROPDOWN MENU----------------------- */

.mainDropdown {
  display: none !important;
}
.mainDropdown .drop {
  position: fixed;
  top: 100px;
  right: 0;
  width: 100vw;
  height: calc(100vh - 100px);
  flex-direction: column;
  z-index: 10000;
  align-items: flex-end !important;
  background: linear-gradient(-90deg, #000000c9 50%, #000000a3 70%, #000000a3);
}
.drop .sidebar {
  position: fixed;
  top: 100px;
  left: 50vw;
  width: 50vw;
  height: calc(100vh - 100px);
  flex-direction: column;
  z-index: 10000;
  min-height: 0 !important;
  animation: slideIn 0.4s ease-in-out;
}
.buttonWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 80px;
  cursor: pointer;
  transition: all 0.5 ease-in-out;
}
.hamburger {
  width: 37px;
  height: 4px;
  background: var(--textColor1);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.hamburger::before,
.hamburger::after {
  content: "";
  position: absolute;
  width: 37px;
  height: 4px;
  background: var(--textColor1);
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
.hamburger::before {
  transform: translate(0, -14px);
}
.hamburger::after {
  transform: translate(0, 14px);
}
.buttonWrapper.open .hamburger {
  transform: translateX(-50px);
  background: transparent;
}
.buttonWrapper.open .hamburger::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.buttonWrapper.open .hamburger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}
.nameAndBack {
  display: none;
}

/* ---------------------- SOCIALS----------------------- */

.socials {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 50px;
}
.socials svg {
  width: 25px;
  fill: #fff;
  cursor: pointer;
}
.home .socials {
  position: fixed;
  bottom: 0;
  left: 20px;
  flex-direction: column;
  row-gap: 35px;
}
.socials svg:hover {
  opacity: 0.7;
}

/* ---------------------- HOME ---------------------- */

.admin {
  background: var(--gradient);
  min-height: 100vh;
}
.adminSignOut {
  background: none;
  border: solid #fff 1px;
  border-radius: 20px;
  padding: 10px 30px;
  margin: 0 5px;
  cursor: pointer;
  color: #fff;
  position: fixed;
  top: 10px;
  right: 10px;
}
.admin button:hover {
  filter: brightness(0.7);
}
.adminInput {
  display: flex;
  width: calc(100% - 100px);
  justify-content: center;
  margin: 5px;
  column-gap: 10px;
}
.admin .formContainerInner {
  background: #fff;
  padding: 20px;
}
.admin .formContainerOuter {
  height: 100vh;
}
.adminInput input {
  width: 100%;
  color: #000;
  flex: 2;
}
.adminInput button {
  width: 150px;
}
.admin span {
  color: #000;
}
.channelsInput {
  flex: 2;
  display: flex;
  column-gap: 10px;
}
.admin .logo {
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 50px;
}

/* ---------------------- ANIMATIONS----------------------- */

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideIn {
  0% {
    left: 100vw;
  }
  100% {
    left: 50vw;
  }
}
@keyframes slideIn2 {
  0% {
    left: 100vw;
  }
  100% {
    left: 30vw;
  }
}
@keyframes slideLeft {
  0% {
    left: 100vw;
  }
  100% {
    left: 0;
  }
}
@keyframes slideRight {
  0% {
    left: 0vw;
  }
  100% {
    left: 100vw;
  }
}

/* ---------------------- MOBILE MEDIAS----------------------- */

@media only screen and (max-width: 1500px) {
  .newWatchlist {
    width: 70vw;
  }
  .formContainerInner {
    width: 50%;
  }
  .individualWatchlist {
    width: 80%;
  }
}
@media only screen and (max-width: 1250px) {
  .formContainerInner {
    width: 60%;
  }
}
@media only screen and (max-width: 1000px) {
  .newWatchlist {
    width: 80vw;
  }
  .mainContent {
    padding: 0;
  }
  .mainContent .navbar {
    padding: 0 20px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--gradient);
    position: sticky;
    z-index: 1000;
    top: 0;
  }
  .mainContent .navbar img {
    filter: brightness(1);
  }
  .mainContent .navbar img:hover {
    filter: brightness(0.8);
  }
  .mainContent .navbar .logo {
    display: flex;
    text-align: center;
    cursor: pointer;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    font-weight: 600;
    color: var(--textColor1);
  }
  .mainDropdown {
    display: flex !important;
  }
  .navSearch {
    display: none;
  }
  .drop .sidebar input {
    display: block;
    border-radius: 0;
    padding: 15px 30px;
    margin: 0 0 10px;
    color: var(--textColor1);
    width: calc(100% - 60px);
  }
  .drop {
    top: 80px !important;
    height: calc(100vh - 80px) !important;
  }
  .drop .sidebar input::placeholder {
    color: var(--placeholderText);
  }
  .sidebar button {
    margin: 3px 0;
    padding: 15px 0;
    width: 100%;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sidebar {
    display: none;
    justify-content: space-between !important;
    padding: 0;
    top: 80px !important;
    height: calc(100vh - 80px) !important;
  }
  .navbar .nameAndBack {
    display: none !important;
  }
  .nameAndBack {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin: 10px 0 0;
  }
  .nameAndBack h1 {
    color: var(--textColor2);
    font-weight: 400;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .nameAndBack img {
    filter: brightness(0);
    cursor: pointer;
    margin-left: 10px;
  }
  .nameAndBack img:hover {
    filter: brightness(0.5);
  }
  .discord,
  .watchlistScreen {
    justify-content: center;
  }
}
@media only screen and (max-width: 750px) {
  .formContainerInner {
    width: 75%;
  }
  .individualWatchlist {
    width: 90%;
  }
  .edit-delete {
    width: 90%;
  }
  .buttonsWrapper {
    display: flex;
    row-gap: 15px;
    flex-direction: column;
  }
  .mask1 {
    font-size: 200px;
    line-height: 150px;
  }
  .home h2 {
    font-size: 20px;
  }
  .newWatchlist {
    width: 90vw;
  }
  .header h2 {
    font-size: 13px;
  }
  .header h1 {
      font-size: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .watchlist {
    width: 80%;
  }
}
@media only screen and (max-width: 500px) {
  .formContainerInner {
    width: 100%;
  }
  .newWatchlist {
    width: 95vw;
  }
  .mask1 {
    font-size: 130px;
    line-height: 95px;
  }
  .home h2 {
    font-size: 18px;
    text-align: center;
  }
  .invite,
  .enter {
    padding: 16px 25px;
  }
  .drop {
    background: linear-gradient(
      -90deg,
      #000000c9 70%,
      #000000a3 80%,
      #000000a3
    ) !important;
  }
  .drop .sidebar {
    width: 70vw !important;
    left: 30vw;
    animation: slideIn2 0.4s ease-in-out !important;
  }
  .mainContent .navbar div {
    column-gap: 10px;
  }
}
